import { IVideoLegacyService, IGetVideoURL } from '../../VideoLegacy.types';
import { buildUrl } from '../utils';

const getUrl: IGetVideoURL = ({
  videoId,
  autoplay,
  isPlayingAllowed = true,
  facebookConfig,
}) => {
  const baseUrl = `//www.facebook.com/plugins/video.php?href=https://www.facebook.com/${videoId}`;
  const { width, height } = facebookConfig;

  const params = {
    autoplay: autoplay && isPlayingAllowed,
    width,
    height,
  };

  return buildUrl(baseUrl, params);
};

const title = 'External Facebook';

export default { getUrl, title } as IVideoLegacyService;
